<template>
    <DifferentiatorGrid v-editable="blok" :data="differentiatorGrid" />
</template>

<script setup lang="ts">
import DifferentiatorGridType from '~/types/DifferentiatorGridType';
import ImageType from '~/types/ImageType';
import LinkType from '~/types/LinkType';
import DifferentiatorGrid from '~/components/page-building/differentiator-grid/DifferentiatorGrid.vue';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const differentiatorGrid = computed<DifferentiatorGridType>(() => {
    const gridItems = props.blok.grid_items
        ? props.blok.grid_items.map((gridItem: any) => {
              return {
                  image:
                      gridItem.image && gridItem.image.filename
                          ? ({
                                title: gridItem.image.title,
                                alt: gridItem.image.alt,
                                src: gridItem.image.filename,
                            } as ImageType)
                          : null,
                  hoverImage:
                      gridItem.hover_image && gridItem.hover_image.filename
                          ? ({
                                title: gridItem.hover_image.title,
                                alt: gridItem.hover_image.alt,
                                src: gridItem.hover_image.filename,
                            } as ImageType)
                          : null,
                  title: gridItem.title,
                  subtitle: gridItem.subtitle,
                  content: richTextToHtml(gridItem.content) as string,
                  link:
                      gridItem.link && gridItem.link.length > 0
                          ? ({
                                label: gridItem.link[0].label,
                                url: gridItem.link[0].target.cached_url,
                                anchor: gridItem.link[0].anchor,
                            } as LinkType)
                          : null,
                  imageSize: gridItem.image_size,
                  vEditable: gridItem,
              };
          })
        : [];

    return {
        heading: props.blok.heading,
        content: richTextToHtml(props.blok.content) as string,
        items: gridItems,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
        arrowCallout: props.blok.arrow_callout_type
            ? {
                  type: props.blok.arrow_callout_type,
                  text: richTextToHtml(props.blok.arrow_callout_text) as string,
              }
            : null,
        variant: props.blok.variant,
    };
});
</script>
